import Service from '../Service';

const resource = "processcontainers/";


export default {

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    savecarcontainer(prd, requestID) {
        return Service.post(resource + "savecarcontainer", prd, {
            params: { requestID: requestID },
        });
    },

    getDetail(FpcID, requestID) {
        return Service.post(resource + "getdetailcontainer", null, {
            params: { FpcID:FpcID, requestID: requestID
                         },
        });
    },
    transfer(prd, requestID) {
        return Service.post(resource + "transfer", prd, {
            params: { requestID: requestID },
        });
    },

    
};