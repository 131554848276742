<template>
    <div>
        <s-crud
            title="Contenedores de Proceso"
            height="auto"
            :config="config"
            :filter="filter"
            add
            @save="save($event)"
            edit
            remove
            search-input>
            <template v-slot="props">
                <v-container>
                    <v-row>
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-text
                                label="Descripcion"
                                v-model="props.item.FpcDescription"
                                ref="txtFpcDescription">
                            </s-text>
                        </v-col>
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-text
                                label="Capacidad Carritos"
                                v-model="props.item.FpcCapacity"
                                ref="txtFpcCapacity">
                            </s-text>
                        </v-col>
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-text
                                label="Capacidad Toneladas"
                                v-model="props.item.FcpCapacityTon"
                                ref="txtFcpCapacityTon">
                            </s-text>
                        </v-col>
                        <v-col cols="3" lg="3" md="6" sm="12"> 
                            <s-select-definition 
                                :def=1431 label="Tipo Tunel Acondicionado" 
                                v-model=props.item.TypeProcessContainers>
                            </s-select-definition>  
                        </v-col>
                        <v-col cols="3" lg="3" md="6" sm="12"> 
                            <s-select-definition 
                                :def=1154 label="Estado" 
                                v-model=props.item.FpcStatus>
                            </s-select-definition>  
                        </v-col>
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-select :items="LocationArea"
                                label="Area"
                                item-value="FpcArea"
                                v-model=props.item.FpcArea
                                item-text="FpcAreaName">
                            </s-select>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:FpcStatus="{row}">
                <v-icon :color="row.FpcStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
    </div>
</template>
<script>

    import _sProcessContainers from '@/services/FrozenProduction/FrzCtnProcessContainers.js';
    export default{
        data(){
            return {
                config:{
                    model:{
                        FpcID: "ID",
                        FpcStatus:"FpcStatus",
                    },
                    service: _sProcessContainers,
                    headers:[
                        {text:"ID", value:"FpcID"},
                        {text:"Descripcion", value:"FpcDescription"},
                        {text:"Capacidad", value:"FpcCapacity"},
                        {text:"Capacidad Ton.", value:"FcpCapacityTon"},
                        {text:"Tipo Container", value: "TypeProcessContainersDescription"},
                        {text:"Ubicacion", value: "FpcAreaName"},
                        {text:"Estado", value:"FpcStatus", align:"center", sorteable:true},
                    ]
                },
                filter:{},
                LocationArea: [
                    { FpcArea: 1, FpcAreaName: 'Acondicionado'},
                    { FpcArea: 2, FpcAreaName: 'Empaque'}
                ],
            }
        },
        methods:{
            save(item){

                if(item.FpcDescription == ""){
                    this.$fun.alert("Ingrese descripcion", "warning");
                    this.$refs.txtFpcDescription.focus();
                    return;
                }
                item.UsrID =  this.$fun.getUserID();
                item.UsrUpdate =  this.$fun.getUserID();
                item.save();
            }
        }
    }
</script>